import gql from 'graphql-tag';

export const LegacyContentQuery = gql`
	query LegacyContentQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				body {
					view {
						value
						webresource {
							keys
						}
					}
				}
				history {
					createdBy {
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
						profilePicture {
							path
						}
						displayName
					}
				}
				type
			}
		}
	}
`;
