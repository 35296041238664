import gql from 'graphql-tag';

export const inlineCommentFragment = gql`
	fragment inlineCommentFragment on Comment {
		id
		body(representation: ATLAS_DOC_FORMAT) {
			value
		}
		contentStatus
		links {
			webui
			editui
		}
		location {
			... on InlineComment {
				type
				inlineMarkerRef
				inlineResolveProperties {
					resolvedTime
					resolvedUser
				}
			}
		}
		parentId
		permissions {
			isEditable
			isRemovable
			isResolvable
		}
		version {
			when
			number
		}
		author {
			displayName
			profilePicture {
				path
			}
			permissionType
			... on KnownUser {
				accountId
			}
			... on User {
				accountId
			}
		}
	}
`;
