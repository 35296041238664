import type { FC } from 'react';
import React, { Fragment, useState, useCallback } from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage } from 'react-intl-next';

import { useChoreographerAssets } from '@confluence/choreographer-services';

import { token } from '@atlaskit/tokens';
import { N0, N50, N100, N800, B400 } from '@atlaskit/theme/colors';

export type DisabledAction = 'edit' | 'delete';

type DisabledCommentActionProps = {
	action: DisabledAction;
	isReply?: boolean;
	isInOverflowMenu?: boolean;
};

type DisabledActionContainerProps = {
	isReply?: boolean;
	isInline?: boolean;
	isInOverflowMenu?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupPaddingContainer = styled.div({
	backgroundColor: token('color.background.neutral.bold', N800),
	color: token('color.text.inverse', N0),
	padding: `${token('space.025', '2px')} ${token('space.075', '6px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledActionContainer = styled.span<DisabledActionContainerProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isReply ? token('color.text.subtle', N100) : token('color.text.subtlest', N50),
	cursor: 'pointer',
	userSelect: 'none',
	flex: '1 1 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (props) =>
		props.isInOverflowMenu
			? `${token('space.100', '8px')} ${token('space.250', '20px')} ${token(
					'space.100',
					'8px',
				)} ${token('space.200', '16px')}`
			: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	fontSize: '12px',
	width: '200px',
	userSelect: 'none',
	whiteSpace: 'normal',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLink = styled.span({
	color: token('color.text.brand', B400),
	display: 'inline',
	padding: 0,
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		textDecoration: 'underline',
	},
});

export const DisabledCommentAction: FC<DisabledCommentActionProps> = ({
	action,
	isReply,
	isInOverflowMenu,
}) => {
	const { Popup } = useChoreographerAssets();
	const [showDialog, setShowDialog] = useState(false);

	// make the dialog visible over the dropdown menu portal
	const dropdownMenu = document.querySelector(
		`[data-testid="comment-overflow-menu--content"]`,
	) as HTMLElement;

	if (dropdownMenu && dropdownMenu.style) {
		dropdownMenu.style.overflow = 'visible';
	}

	const toggleDialog = useCallback(
		(e: any) => {
			e.stopPropagation();
			setShowDialog(!showDialog);
		},
		[showDialog],
	);

	const triggerReplyEditor = () => {
		const textArea: HTMLElement | null = document.querySelector(`[placeholder="Reply"]`);

		if (textArea) {
			textArea.focus();
			setShowDialog(false);
		}
	};

	const DialogContent = (
		<Container data-testid="disabled-explanation-dialog">
			{action === 'edit' ? (
				<Fragment>
					<StyledLink data-testid="disabled-edit-action-link" onClick={triggerReplyEditor}>
						<FormattedMessage
							id="comment.no.edit.action.text"
							defaultMessage="Add a new comment"
							description="option to add a new comment"
						/>
					</StyledLink>{' '}
					<FormattedMessage
						id="comment.no.edit.reason.text"
						defaultMessage="since this one cannot be edited; it contains content created in a legacy version of Confluence."
						description="reason for why we prevent editing the content"
					/>
				</Fragment>
			) : (
				<FormattedMessage
					id="comment.no.delete.reason.text"
					// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
					// eslint-disable-next-line no-restricted-syntax
					defaultMessage="You can't delete this comment because it has replies."
					description="reason why we prevent deleting the comment"
				/>
			)}
		</Container>
	);

	const renderActionText = () => {
		if (action === 'edit') {
			return isReply ? (
				<FormattedMessage
					id="comment.no.edit.inline.label"
					defaultMessage="Edit is not available"
					description="clickable text that tells the user they cannot edit this content"
				/>
			) : (
				<FormattedMessage
					id="comment.no.edit.overflow.menu.label"
					defaultMessage="Edit"
					description="clickable text that is disabled in the overflow menu"
				/>
			);
		}

		return (
			<FormattedMessage
				id="comment.no.delete.overflow.menu.label"
				defaultMessage="Delete"
				description="clickable text that is disabled in the overflow menu"
			/>
		);
	};

	return (
		<Popup
			messageType="transactional"
			messageId="disabled-comment-action"
			content={() => <PopupPaddingContainer>{DialogContent}</PopupPaddingContainer>}
			isOpen={showDialog}
			placement={isReply ? 'bottom' : 'bottom-end'}
			trigger={(triggerProps) => (
				<DisabledActionContainer
					data-testid="disabled-action-container"
					onClick={toggleDialog}
					isReply={isReply}
					isInOverflowMenu={isInOverflowMenu}
					{...triggerProps}
				>
					{renderActionText()}
				</DisabledActionContainer>
			)}
		/>
	);
};
