import memoize from 'memoize-one';

import type { EventHandlers } from '@atlaskit/editor-common/ui';

import { isExternalShareRequest } from '@confluence/external-share-utils';
import type { RoutesContextType } from '@confluence/route-manager';
import { getSpaTransitionHref } from '@confluence/route-manager';
import { isExternalLink } from '@confluence/linking-platform';

export const createLinkEventHandler = memoize(
	(routesContext: Pick<RoutesContextType, 'matchRoute' | 'push'>): EventHandlers | undefined =>
		routesContext && typeof routesContext.push === 'function'
			? {
					link: {
						onClick(e, url) {
							const isInlineComment =
								e.target instanceof Element &&
								e.target.matches('[data-mark-annotation-type="inlineComment"]');
							if (isInlineComment) return;

							const spaTransitionHref = getSpaTransitionHref(e, routesContext);
							if (isExternalLink(url)) {
								e.preventDefault();
								window.open(url, '_blank', 'noopener, noreferrer');
							} else if (spaTransitionHref && spaTransitionHref === url) {
								e.preventDefault();
								routesContext.push(spaTransitionHref, isExternalShareRequest());
							}
						},
					},
				}
			: undefined,
);
