import gql from 'graphql-tag';

export const InlineCommentAuthorQuery = gql`
	query InlineCommentAuthorQuery($commentId: ID!) {
		content(id: $commentId) {
			nodes {
				id
				history {
					createdBy {
						displayName
						profilePicture {
							path
						}
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
			}
		}
	}
`;
