import memoize from 'memoize-one';

import type { EventHandlers } from '@atlaskit/editor-common/ui';

import type { RoutesContextType } from '@confluence/route-manager';
import { smartCardEventHandler } from '@confluence/linking-platform';

export const createSmartCardEventHandler = memoize(
	(routesContext: Pick<RoutesContextType, 'matchRoute' | 'push'>): EventHandlers | undefined =>
		routesContext && typeof routesContext.push === 'function'
			? {
					smartCard: {
						onClick: (e, url) => smartCardEventHandler(routesContext, e, url),
					},
				}
			: undefined,
);
