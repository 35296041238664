/**
 * This is a temporary exception list which is used to determine if the link
 * is pointing to either Jira, a different instance or outside of Atlassian
 * entirely.
 */
export const isExternalLink = (url: string | undefined) => {
	const windowHostname = window.location.hostname;

	// Only including support for certain Jira paths to minimise hardcoding
	const linkClickExceptionPaths = ['/browse', '/jira'];
	let urlObject: URL | undefined | '';
	try {
		urlObject = url && new URL(url);
	} catch {}

	const { hostname, pathname } = urlObject || {};
	return (
		(hostname && windowHostname && hostname !== windowHostname) ||
		linkClickExceptionPaths.some((exceptionPath) => pathname?.startsWith(exceptionPath))
	);
};
