/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button/standard-button';
import ModalDialog, { ModalBody, ModalFooter } from '@atlaskit/modal-dialog';

import { evalLegacyConnectInlineScripts } from '@confluence/connect-utils';
import { ProfileAvatar, ProfileLink } from '@confluence/profile';
import { requireLegacyWRM } from '@confluence/wrm';

type User = {
	userId: string;
	avatarUrl: string;
	displayName: string;
};

type LegacyContentModalProps = {
	onClose: (val: boolean) => void;
	content: string;
	user: User;
	webResources: [];
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	paddingTop: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	paddingLeft: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		paddingTop: token('space.050', '4px'),
	},
});

export const LegacyContentModal = ({
	onClose,
	content,
	user,
	webResources,
}: LegacyContentModalProps) => {
	const [containerRef, setContainerRef] = useState<HTMLSpanElement | null>(null);
	useEffect(() => {
		requireLegacyWRM(
			webResources,
			() => {},
			() => {},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (containerRef) {
			void evalLegacyConnectInlineScripts(containerRef);
		}
	}, [containerRef]);

	const onRefSet = (ref: any) => {
		setContainerRef(ref);
	};

	const handleClose = () => {
		onClose(false);
	};

	const handleImageClick = (event: any) => {
		// clicking on an image shows a full screen modal
		// of the image in the background
		if (event.target instanceof HTMLImageElement) {
			onClose(false);
		}
	};

	return (
		<ModalDialog width="large">
			<ModalBody>
				<Container>
					<ProfileAvatar userId={user.userId} src={user.avatarUrl} name={user.displayName} />
					<ContentContainer onClick={handleImageClick}>
						<ProfileLink userId={user.userId} fullName={user.displayName} />
						<span ref={onRefSet} dangerouslySetInnerHTML={{ __html: content }} />
					</ContentContainer>
				</Container>
			</ModalBody>
			<ModalFooter>
				<Button autoFocus appearance="primary" onClick={handleClose}>
					Close
				</Button>
			</ModalFooter>
		</ModalDialog>
	);
};
