import React from 'react';

import type { ExtensionHandler, ExtensionHandlers } from '@atlaskit/editor-common/extensions';

import { getMacroAttributesFromADFNode, RENDERER } from '@confluence/macro-tracker';
import type { Mode } from '@confluence/macro-tracker';
import {
	EXTENSION_TYPE,
	MIGRATION_EXTENSION_KEYS,
	EXTENSION_KEY,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import { LegacyMacroRenderer } from '@confluence/fabric-extension-legacy-macro-renderer';

import { supportedMacroExtensions } from './commentExtensionConstants';
import { FallbackComponent } from './FallbackComponent';
import { UnsupportedFallbackComponent } from './UnsupportedFallbackComponent';
import type { ExtensionHandlerProps } from './commentExtensionTypes';

type GetMacroExtensionHandlerFn = (input: {
	setLegacyRendererFlag?: (extensionKey?: string | undefined) => void;
	contentId?: string;
	spaceKey?: string;
	mode?: Mode;
}) => ExtensionHandler<any>;

type GetMigrationExtensionHandlerFn = (
	setLegacyRendererFlag?: ((extensionKey?: string | undefined) => void) | undefined,
	contentId?: string,
) => ExtensionHandler<any>;

const context = {
	insertCss: (styles: any) => styles._insertCss(),
};

export const getExtensionHandlers = (
	setLegacyRendererFlag?: (extensionKey?: string | undefined) => void,
	contentId?: string,
	spaceKey?: string,
	mode?: Mode,
): ExtensionHandlers => {
	const macroHandler: object = {
		[EXTENSION_TYPE.MACRO_CORE]: getMacroExtensionHandler({
			setLegacyRendererFlag,
			contentId,
			spaceKey,
			mode,
		}),
	};

	const migrationHandler: object = {
		[EXTENSION_TYPE.MIGRATION]: getMigrationExtensionHandler(setLegacyRendererFlag, contentId),
	};

	return {
		...macroHandler,
		...migrationHandler,
	};
};

const getMacroExtensionHandler: GetMacroExtensionHandlerFn =
	({ setLegacyRendererFlag, contentId, spaceKey, mode }) =>
	(extension) => {
		const { extensionKey } = extension;

		if (setLegacyRendererFlag) {
			setLegacyRendererFlag(extensionKey);
		}

		if (supportedMacroExtensions.includes(extensionKey)) {
			const attributes = getMacroAttributesFromADFNode(extension);
			const macroDefaultProps: ExtensionHandlerProps = {
				node: extension,
				contentId: contentId || '',
				mode: mode || RENDERER,
				spaceKey: spaceKey || '',
				context,
				extensionKey,
				attributes,
			};
			return <LegacyMacroRenderer {...macroDefaultProps} />;
		}

		return <UnsupportedFallbackComponent />;
	};

const getMigrationExtensionHandler: GetMigrationExtensionHandlerFn =
	(setLegacyRendererFlag, contentId) => (extension) => {
		const { extensionKey } = extension;

		if (extensionKey === MIGRATION_EXTENSION_KEYS.INVALID) {
			if (setLegacyRendererFlag) setLegacyRendererFlag();
			return <UnsupportedFallbackComponent />;
		}

		if (
			extensionKey === EXTENSION_KEY.INLINE_EXTERNAL_IMAGE ||
			extensionKey === EXTENSION_KEY.INLINE_MEDIA_IMAGE
		) {
			if (setLegacyRendererFlag) setLegacyRendererFlag(extensionKey);
			return contentId ? <FallbackComponent contentId={contentId} /> : null;
		}

		return null;
	};
