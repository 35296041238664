import type { FC } from 'react';
import React, { useCallback } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsListener } from '@atlaskit/analytics-next';

import { getUniquePageLoadId } from './UniquePageLoadId';

type Props = {
	channelParam: channelInterface;
	children?: React.ReactNode;
};

interface channelInterface {
	channel: string;
	actionSubjects: Array<string>;
}

export const mediaChannel: channelInterface = {
	channel: 'media',
	actionSubjects: ['mediaCardRender', 'mediaUpload', 'smartLink'],
};

export const editorChannel: channelInterface = {
	channel: 'editor',
	actionSubjects: ['renderer'],
};

export const DynamicAnalyticsListener: FC<Props> = ({ channelParam, children }) => {
	const handleEvent = useCallback(
		(event: UIAnalyticsEvent) => {
			function updateEvent() {
				event.update((payload) => {
					return {
						...payload,
						attributes: {
							...payload.attributes,
							pageLoadInfo: getUniquePageLoadId(),
						},
					};
				});
			}
			if (channelParam.actionSubjects.includes(event.payload.actionSubject)) {
				updateEvent();
			}
		},
		[channelParam],
	);
	return (
		<AnalyticsListener channel={channelParam.channel} onEvent={handleEvent}>
			{children}
		</AnalyticsListener>
	);
};
