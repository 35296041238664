import gql from 'graphql-tag';

export const ReactionsCommentsQuery = gql`
	query ReactionsCommentsQuery($contentId: ID!, $containerId: ID!) {
		comments(commentId: $contentId, contentStatus: [DRAFT, CURRENT]) {
			nodes {
				id
				reactionsSummary(pageId: $containerId, contentType: "page", childType: "comment") {
					reactionsCount
					ari
					containerAri
					reactionsSummaryForEmoji {
						emojiId
						count
						reacted
						id
					}
				}
			}
		}
	}
`;
