import type { FC } from 'react';
import React, { createContext, useContext, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { ATL_PAGE_CONTENT_FOOTER_ACTIONS } from '@confluence/web-item-location/entry-points/ATL_PAGE_CONTENT_FOOTER_ACTIONS';
import { WebPanelLocationQuery } from '@confluence/web-panel-location/entry-points/WebPanelLocationQuery';

export type ReactionsContextValue = {
	isReactionsEnabled: boolean;
};

export const ReactionsContext = createContext<ReactionsContextValue>({
	isReactionsEnabled: false,
});
ReactionsContext.displayName = 'ReactionsContext';

type ReactionsProviderProps = {
	contentId: string;
	children?: React.ReactNode;
};

export const ReactionsProvider: FC<ReactionsProviderProps> = ({ contentId, children }) => {
	const { location } = useContext(RoutesContext);

	const isPDFExport = location?.pathname.indexOf('/wiki/pdf/') !== -1;

	const { data } = useQuery(WebPanelLocationQuery, {
		variables: {
			contentId,
			location: ATL_PAGE_CONTENT_FOOTER_ACTIONS,
		},
		fetchPolicy: 'cache-first',
		skip: isPDFExport,
	});

	const reactionsContextValue = useMemo(
		() => ({
			isReactionsEnabled: !isPDFExport && data && data.webPanels && data.webPanels.length > 0,
		}),
		[data, isPDFExport],
	);

	return (
		<ReactionsContext.Provider value={reactionsContextValue}>{children}</ReactionsContext.Provider>
	);
};
