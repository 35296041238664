import React from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import ErrorIcon from '@atlaskit/icon/glyph/error';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContainer = styled.span({
	display: 'flex',
	padding: `${token('space.150', '12px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> span:first-of-type': {
		paddingRight: token('space.100', '8px'),
	},
});

export const UnsupportedFallbackComponent = () => (
	<StyledContainer>
		<ErrorIcon label="unsupported-legacy-error" />
		<FormattedMessage
			id="comment-extension-handlers.unsupported.fallback"
			defaultMessage="This content cannot be displayed as it was created in a legacy version of Confluence and is unsupported."
			description="Reason for why we cannot display the unsupported fallback content"
		/>
	</StyledContainer>
);
