import React from 'react';

import { LoadableAfterPaint, LoadableBackground } from '@confluence/loadable';

import { ReactionsPlaceholder } from './ReactionsPlaceholder';

export const ReactionsLoader = LoadableAfterPaint({
	loading: () => <ReactionsPlaceholder useAccuratePills />,
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Reactions" */ './Reactions')).Reactions,
});

export const ReactionsLoaderBackground = LoadableBackground({
	loading: () => <ReactionsPlaceholder />,
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Reactions" */ './Reactions')).Reactions,
});
