import React, { memo, useState, useMemo, useContext } from 'react';
import { styled } from '@compiled/react';

import type { DocNode } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents, AnalyticsListener } from '@atlaskit/analytics-next';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { token } from '@atlaskit/tokens';

import {
	ADFRenderer,
	useEventHandlers,
	createSmartCardEventHandler,
	createLinkEventHandler,
} from '@confluence/adf-renderer';
import { getExtensionHandlers, FALLBACK_REASONS } from '@confluence/comment-extension-handlers';
import { DynamicAnalyticsListener, editorChannel } from '@confluence/unique-page-load-id';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { expVal } from '@confluence/feature-experiments';

// Approx Scroll Height for Comment Container with 2 Lines
const MIN_SCROLL_HEIGHT = 50;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentRendererContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		paddingTop: token('space.100', '8px'),
		paddingBottom: token('space.100', '8px'),
	},
});

export type CommentRendererProps = {
	commentId: string;
	adf: DocNode;
	onContentFallback?: (extensionKey?: string) => void;
	isTruncatedContent?: boolean;
	onRendered?: () => void;
	maxHeight?: number;
	fadeOutHeight?: number;
	onComplete?: () => void;
	isInlineComment?: boolean;
};

const CommentRenderer = memo(
	({
		commentId,
		adf,
		onContentFallback,
		isTruncatedContent,
		onRendered,
		maxHeight,
		fadeOutHeight,
		onComplete,
		isInlineComment,
	}: CommentRendererProps) => {
		const [rootEl, setRootEl] = useState<HTMLDivElement | null>(null);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const handleContentFallback = (extensionKey?: string) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'fellback',
					actionSubject: 'comment',
					actionSubjectId: commentId,
					source: 'comment',
					attributes: {
						editor: 'v2',
						commentType: 'page',
						reason: extensionKey
							? `${FALLBACK_REASONS.UNSUPPORTED_EXTENSION} - ${extensionKey}`
							: FALLBACK_REASONS.INVALID_ADF,
					},
				},
			}).fire();

			onContentFallback && onContentFallback(extensionKey);
		};

		const extensionHandlers: ExtensionHandlers = getExtensionHandlers(
			handleContentFallback,
			commentId,
		);
		const routesContext = useContext(RoutesContext);
		const embeddedConfluenceEventHandlers = useEventHandlers();

		const eventHandlers = useMemo(
			() => ({
				...createSmartCardEventHandler(routesContext),
				...createLinkEventHandler(routesContext),
				...embeddedConfluenceEventHandlers,
			}),
			[routesContext, embeddedConfluenceEventHandlers],
		);

		/* Logic to not show Fade when we have 2 lines in Inactive state. When we have more than 2 lines the scrollHeight is
  greater than 50, if its less than 50 send fadeOutHeight as 0*/
		const getFadeHeight = () => {
			if (
				isTruncatedContent &&
				(rootEl?.firstElementChild?.scrollHeight ?? 0) > MIN_SCROLL_HEIGHT
			) {
				return fadeOutHeight;
			} else {
				return 0;
			}
		};

		const injectObjectIdIntoRendererAnalytics = (objectId: string) => (event: UIAnalyticsEvent) => {
			// WS-3190 If a renderer event is fired, inject the commentId as objectId
			if (event.payload.actionSubject === 'renderer') {
				event.update((payload) => ({
					...payload,
					attributes: {
						...payload.attributes,
						appearance: 'comment',
						objectId,
					},
				}));
			}
		};

		return (
			<AnalyticsListener
				onEvent={injectObjectIdIntoRendererAnalytics(commentId)}
				channel="editor" // We use the same channel for editor/renderer https://data-portal.internal.atlassian.com/analytics/registry/27447
			>
				<DynamicAnalyticsListener channelParam={editorChannel}>
					<CommentRendererContainer ref={setRootEl}>
						<RendererActionsContext>
							<ADFRenderer
								document={adf}
								extensionHandlers={extensionHandlers}
								portal={rootEl ?? undefined}
								allowStickyHeaders={false}
								isTruncatedContent={isTruncatedContent}
								onRendered={onRendered}
								onComplete={onComplete}
								allowSelectAllTrap
								maxHeight={maxHeight}
								fadeOutHeight={getFadeHeight()}
								appearance="comment"
								eventHandlers={eventHandlers}
								UNSTABLE_allowTableAlignment={
									!isInlineComment &&
									expVal<boolean>(
										'platform_editor_support_table_in_comment_exp',
										'isEnabled',
										false,
									)
								}
								UNSTABLE_allowTableResizing={
									!isInlineComment &&
									expVal<boolean>(
										'platform_editor_support_table_in_comment_exp',
										'isEnabled',
										false,
									)
								}
							/>
						</RendererActionsContext>
					</CommentRendererContainer>
				</DynamicAnalyticsListener>
			</AnalyticsListener>
		);
	},
);
CommentRenderer.displayName = 'CommentRenderer';
export { CommentRenderer };
