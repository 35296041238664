export const MIGRATION_EXTENSION_KEYS = {
	INVALID: '__confluenceADFMigrationUnsupportedContentInternalExtension__',
};

export const FALLBACK_REASONS = {
	UNSUPPORTED_EXTENSION: 'unsupportedExtension',
	INVALID_ADF: 'invalidAdf',
};

export const supportedMacroExtensions: string[] = [
	'jira',
	'attachments',
	'bgcolor',
	'blog-posts',
	'center',
	'chart',
	'column',
	'contentbylabel',
	'content-by-user',
	'rw-ui-layout-macro',
	'content-report-table',
	'profile-picture',
	'contributors-summary',
	'copyright',
	'excerpt',
	'excerpt-include',
	'fancy-bullets',
	'favpages',
	'gallery',
	'global-reports',
	'map',
	'highlight',
	'listlabels',
	'mathblock',
	'mathinline',
	'loremipsum',
	'navmap',
	'network',
	'index',
	'details',
	'detailssummary',
	'panel',
	'popular-labels',
	'privacy-mark',
	'privacy-policy',
	'recently-updated',
	'recently-used-labels',
	'reg-tm',
	'section',
	'sm',
	'space-details',
	'strike',
	'tasks-report-macro',
	'tm',
	'rw-ui-textbox-macro',
	'userlister',
	'profile',
	'jirachart',
	'jiraroadmap',
	'gadget',
	'contributors',
	'widget',
];
