import type { SyntheticEvent } from 'react';

import type { SmartCardEventClickHandler } from '@atlaskit/editor-common/ui';

import { isExternalShareRequest } from '@confluence/external-share-utils';
import type { RoutesContextType } from '@confluence/route-manager';
import { getSpaTransitionHref } from '@confluence/route-manager';

import { isClickEventToOpenNewTab } from './isClickEventToOpenNewTab';
import { isExternalLink } from './isExternalLink';
import { fg } from '@atlaskit/platform-feature-flags';

// TODO Once block smartcards no longer have contenteditable in live view mode, remove this logic and the `skipContentEditableCheck` getSpaTransitionHref argument
const isBlockCardLink = (e: SyntheticEvent<HTMLAnchorElement>): boolean => {
	const target = e.target instanceof Element ? e.target : undefined;
	return !!target && target.getAttribute('data-smart-element') === 'Title';
};

export const smartCardEventHandler = (
	routesContext: Pick<RoutesContextType, 'matchRoute' | 'push'>,
	e: SyntheticEvent<HTMLAnchorElement>,
	url?: string,
) => {
	// If clicking block card link, we set `skipContentEditableCheck` to `true` when calling getSpaTransitionHref. This is a workaround to allow block smartcard links to have SPA transitions.
	// Block smartcards have contentEditable set to true as a platform bug workaround. There is no UX intention that block smartcard content be edited.
	const shouldSkipContentEditableCheck = fg('linking-platform-contenteditable-false-live-view')
		? false
		: isBlockCardLink(e);
	const spaTransitionHref = getSpaTransitionHref(e, routesContext, shouldSkipContentEditableCheck);

	if (isExternalLink(url)) {
		e.preventDefault();
		window.open(url, '_blank', 'noopener, noreferrer');
	} else if (spaTransitionHref && spaTransitionHref === url) {
		e.preventDefault();
		routesContext.push(spaTransitionHref, isExternalShareRequest());
	} else {
		handleClick(e, url);
	}
};

/**
 * Copied from @atlaskit/smart-card and
 * packages/media/smart-card/src/view/CardWithUrl/component.tsx to handle click
 * on a smart card with a URL because @atlaskit/smart-card expects
 * SmartCardEventClickHandler to handle the event fully, doesn't allow the
 * SmartCardEventClickHandler to fall back to the default @atlaskit/smart-card
 * click handler.
 */
const handleClick: SmartCardEventClickHandler = (e, url) => {
	isClickEventToOpenNewTab(e, { shouldCheckIframe: true })
		? window.open(url, '_blank')
		: window.open(url, '_self');
};
